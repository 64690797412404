<script>

import appConfig from "../../../app.config";
import axios from 'axios';


export default {


  page: {
    title: "verifyregister",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  data() {

  },
  validations: {

  },
  mounted: function () {
    var q = this.$route.params.id
    this.method1(q) //method1 will execute at pageload
  },
  computed: {

  },
  methods: {
    method1: async function (q) {
     
     // alert("q=="+q);
      //+
      const result = await axios.get('http://localhost:4700/verifyregister/'+q)

     // alert(JSON.stringify(result.data));
    //  console.log(JSON.stringify(result));
      if (result.data.status == 'errors') {
        return this.authError = result.data.data.data;
      }
      
      this.$router.push({
        path: '/signin/Email has been verified. Now you can login'
      });
    }
  },
};
</script>

<template>
  <h1>sss</h1>
  <!-- end auth-page-wrapper -->
</template>